import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Innovation Consulting",
  "description": "Innovation consulting, one of the earliest service that we provide, ensures new hardware startups can realize their ideas into life, through design, planning, and strategy.",
  "author": "",
  "categories": ["onboarding"],
  "date": null,
  "featured": true,
  "tags": ["onboarding"],
  "relatedArticles": ["DFX", "new-technology-introduction-nti", "nsf"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As a partner in your journey to create innovative products, NexPCB provides insights to shorten your time to market, focus on research and marketing, also to allocate your resources better.`}</p>
    <p>{`With 17+ years of experience and a vast supplier network, NexPCB will guide you to do cost optimization, risk management, design support, and many more.`}</p>
    <p>{`Innovation has been a huge part of our daily life. As humans, we continue to innovate, moving from one era to another. These innovations are implemented into our society, improving our quality of life.`}</p>
    <p>{`The cycle starts from:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Technical discoveries`}</strong>{`. These start from the theories made by people in the past, which drives the present people to prove their accuracy.`}</li>
      <li parentName="ol"><strong parentName="li">{`Invention`}</strong>{`. This is done when the proven principles are brought into practical form. The creation of something new, something to be used.`}</li>
      <li parentName="ol"><strong parentName="li">{`Society Utilization`}</strong>{`. At this time, the invention has become widely used by people in society.`}</li>
      <li parentName="ol">{`More `}<strong parentName="li">{`research and development`}</strong>{` will arise once the practice has become common. Improvements will continuously be made to enhance better life quality.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "665px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "81.46718146718148%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABcRAAAXEQHKJvM/AAAEsklEQVQ4yz3Ua0zTVxQA8EO7bPvgh2XZZ7f5iHFxIzMCZpvOTawdCM6hwxXraBVRZwCdBmgFW2ApUPlDi23hT5U+rLRgeWhrNyk6ZDzMZjJnLdaQQSlFdAqVfx+09M9ZWh8nOffTzS/nnnvPhbnnQbjr9AIixjM5k2BKFL2wclMljLq87yj1g2k2+102fbVSFGgXFob7W9fN7QUIDxshqC9izJesg7nctyDquQfxcHtnX2MAnIT0XBLIS0PrCs6Y9TkFOg/vZBsSql+vofbA83l5NgbO59NBw8+XI/f7lofM5RBQcZlUxRcQIHnwMlJfYG/zGB9+XgFCqSVvX6E+ytqnwqSMOly7tRpzjjS3YSPbPVeWgtQvX9H+xmwMaH56Ev5DnxjqFEHQcIIR1BcBHfC9AFM5Subuw61w+qw1d+8xLaZkErhqc1VkzZaqyMrNVZjFV7SjIm1q7nQSzpcn0fNlG8J+IhOD2mOT0Sf/vrs4NgLhAV2CK8aJ6m0JCu0AmG13l3OL9NT3RzW4eY88up2rwgweGd2SrcAf8ptMqEif8klYSEm/WaJq2UhJUsMBJQeD7QKCqmaBX5rGjIP7CvXM/ccvQrHkSkV6bjN+zKpZSMkkorvyzkdTOYrwR6nVmJ2nMqIifdIn34sB9aHFgPpg1N+YHYnBgQuHJ2g6uoymaYg4+hJiZnzhn7x0JzmDwI0765HNbUL2/iZkc1W48dsGzOIpelC+bdYn3oT+mu3or05FqnITUuLPMNh6BBfsqrRQ2ykI6goYr274DYH0mir7aOvv27kq6w4eacvgk7ZMPmllcZv6CwQGAV48dNFXn2WnZLttlHyPjZJl2fwk/1rIerYvcruDHeoQQuhyecIrMOFWf19zp9k0LmuQOhrqpc76uhpnAyF1yIga9wW9jthqp24mdk6Pfdo1cz+xa8a5vvuxM9E87eDfeuYR319MOX5nAfJH/AzgHzjAOHgwD3Q6vYIg6rGkpBQrKiuxprYWJZJqLC4uRqnsXFdS19R/a7RO/MTgiuca3QNcrXuAu60TWDXi3SAa9sLxfg8DDAYD02g0gtVq3apUKlEsFkeUSiXdTJI02dISqRCL8az8nCm5e9qTbHyI27rH6bQrE/R649hicvsYcq97nIj4Zuykt2cCAG63+/WkaDSaP+vq6rC8vDwiFouxoqIyWiYUYl2jwpTSMz31QesortS5lt7XuHCVzhXecXUCTww8OrXT4oYMywRTMDQD4HA4oKOjg2E2m8Fut28kSRIlEgmWlpZGBALholBQikSj0rSh2zu1QjOKK7Su6Gr9wzCrZxx59qlBRGTEiumdpODHXg/AjRs3ICcnByQSCVOlUoHFYtnZ0tKyQBBErJe0oLQEa+WKti8tjyZXaR9gkmkM069OxLCRe09D7yn/eQai248ZpOMZBBdpgKWlpVdDDSKRiKFWq2FwcHCtTqe73KJWzzfK5WgwmmyFI7OzuyzjyPnN4y66NX0m9tRkfz+F/JteRqwyyV9PXiCxcn0+38vfBkAmkzF7e3uBw+HA0NDwB2Zz53cjgwNbmkfnj5YNP2Ij4rKvu8bj+4XDM4yc6x5g9YyDP0LHwf8BDErm6wxU4R8AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/49c6f5a119508d12a4fb7d54c80dd565/652f5/innovation-consulting-01.webp 259w", "/static/49c6f5a119508d12a4fb7d54c80dd565/c29d2/innovation-consulting-01.webp 518w", "/static/49c6f5a119508d12a4fb7d54c80dd565/ced6d/innovation-consulting-01.webp 665w"],
            "sizes": "(max-width: 665px) 100vw, 665px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/49c6f5a119508d12a4fb7d54c80dd565/a2ead/innovation-consulting-01.png 259w", "/static/49c6f5a119508d12a4fb7d54c80dd565/6b9fd/innovation-consulting-01.png 518w", "/static/49c6f5a119508d12a4fb7d54c80dd565/5f4af/innovation-consulting-01.png 665w"],
            "sizes": "(max-width: 665px) 100vw, 665px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/49c6f5a119508d12a4fb7d54c80dd565/5f4af/innovation-consulting-01.png",
            "alt": "innovation-cycle",
            "title": "innovation-cycle",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`As a business growing be a part of the transformation, you can start innovating by doing these 4 steps:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Predict`}</strong></li>
    </ol>
    <p>{`During this process, you’ll be more immersed in `}<strong parentName="p">{`observing and doing research`}</strong>{` for your profound problem.`}</p>
    <p>{`As a business, you should be able to answer the `}<strong parentName="p">{`5W1H (Who, What, Where, Which, How)`}</strong>{` of your products, your estimated `}<strong parentName="p">{`cost allocation, market planning,`}</strong>{` and `}<strong parentName="p">{`buyer persona.`}</strong></p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Observe`}</strong></li>
    </ol>
    <p>{`Take a further analysis of the research done before.`}</p>
    <p>{`Create `}<strong parentName="p">{`business strategies`}</strong>{` to achieve `}<strong parentName="p">{`development goals`}</strong>{` based on `}<strong parentName="p">{`industry conditions.`}</strong></p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Present`}</strong></li>
    </ol>
    <p>{`Show how `}<strong parentName="p">{`mature`}</strong>{` your development plans are, and if you have considered any `}<strong parentName="p">{`risks and possibilities`}</strong></p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Launch!`}</strong></li>
    </ol>
    <p>{`After consulting with us, we’ll assist you to conduct prototyping and production, so you can successfully launch at the `}<strong parentName="p">{`right time, at the target cost, with the right quality`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": ""
        }}><strong parentName="a">{`Lets start working on your project`}</strong></a><strong parentName="p">{`!`}</strong></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      